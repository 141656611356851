const middleware = {}

middleware['shared/address-redirect'] = require('../middleware/shared/address-redirect.js')
middleware['shared/address-redirect'] = middleware['shared/address-redirect'].default || middleware['shared/address-redirect']

middleware['shared/default-redirect'] = require('../middleware/shared/default-redirect.js')
middleware['shared/default-redirect'] = middleware['shared/default-redirect'].default || middleware['shared/default-redirect']

middleware['shared/results-redirect'] = require('../middleware/shared/results-redirect.js')
middleware['shared/results-redirect'] = middleware['shared/results-redirect'].default || middleware['shared/results-redirect']

middleware['shared/trending-name-redirect'] = require('../middleware/shared/trending-name-redirect.js')
middleware['shared/trending-name-redirect'] = middleware['shared/trending-name-redirect'].default || middleware['shared/trending-name-redirect']

export default middleware
