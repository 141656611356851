import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

var environment = 'development';

if (process.env.staging === 'true') {
  environment = 'staging';
} else if (process.env.version && process.env.staging === 'false') {
  environment = 'production';
}

Sentry.init({
  Vue,
  dsn: process.env.sentryUrl,
  integrations: [new Integrations.BrowserTracing()],
  tracingOptions: {
    trackComponents: true
  },
  release:
    process.env.website + '@' + (process.env.version || process.env.branch),
  environment,
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      // Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});
