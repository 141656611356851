/**
 * Trending Name Search Redirect Middleware
 * @param route
 * @param redirect
 */
export default function({ route, redirect, $config: { website } }) {
  const { params } = route;
  const currentYear = new Date().getFullYear();
  const minYear = 2018;
  let redirectUrl;

  switch (website) {
    case 'information':
      redirectUrl = '/popular-names/';
      break;
    case 'checkpeople':
      redirectUrl = '/recent-name-searches';
      break;
    default:
      redirectUrl = '/trending-name-search/';
      break;
  }

  if (
    isNaN(params.year) ||
    params.year > currentYear ||
    params.year < minYear
  ) {
    redirect(redirectUrl);
  }
}
