var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('wl-header',{class:{
      'wl-header--mobile-search': _vm.mobileSearchActive,
      'wl-header--home': _vm.isHome
    },attrs:{"id":"app","logo":_vm.header.logo,"logo-standard-size":_vm.logoSize,"logo-small":_vm.header.logo_small,"logo-small-size":_vm.logoSmallSize,"logo-name":_vm.header.name,"logo-title":_vm.header.logo_title,"menu":_vm.isMobile ? [] : _vm.header.menu.slice(0, _vm.menuLinksDesktopCount),"path":_vm.path,"is-fixed":_vm.isFixed},scopedSlots:_vm._u([{key:"header-bar",fn:function(){return [_c('ul',{staticClass:"wl-header__toggle-menu",attrs:{"id":"toggle-menu"}},_vm._l((_vm.isMobile
            ? _vm.header.menu
            : _vm.header.menu.slice(_vm.menuLinksDesktopCount)),function(link){return _c('li',{key:link.label,staticClass:"wl-header__toggle-menu-item"},[_c('a',{staticClass:"wl-header__toggle-menu-link",class:{
              'wl-header__toggle-menu-link--active': _vm.path === link.url
            },attrs:{"href":link.url,"role":"button","aria-haspopup":"true","aria-expanded":"false"},domProps:{"textContent":_vm._s(link.label)}})])}),0)]},proxy:true}])}),_vm._v(" "),_c('main',{staticClass:"inside-content"},[_c('nuxt',{ref:"page",attrs:{"prefill-fields":_vm.prefillFields}}),_vm._v(" "),_c('tz-footer',{attrs:{"page":_vm.page,"header-fixed":"","footer":_vm.footer,"alpha-base-link":"/","display-sections":[
        'alphaSelect',
        'contactInfo',
        'columnSecondary',
        'columnTertiary',
        'columnQuarternary',
        'disclaimer'
      ]},scopedSlots:_vm._u([{key:"badges",fn:function(){return [_c('img',{attrs:{"src":_vm.securityBadges,"alt":"Trusted site"}})]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }