import { getWithExpiry, setWithExpiry } from '../../assets/js/shared/local-storage';
const locationKey = 'geoip-v1-location';
const ttl = 86400 * 1000; // 1 day

export default ({ app, $config: { apiLocationUrl } }, inject) => {
  inject('location', getLocation);

  /**
   * Get Location
   * @returns {Promise<{state}|*>}
   */
  async function getLocation() {
    const location = getWithExpiry(locationKey);
    if (location && location.state) {
      return location;
    }

    const fetchData = await fetch(apiLocationUrl).then(response =>
      response.json()
    );

    if (fetchData && fetchData.state) {
      setWithExpiry(locationKey, fetchData, ttl);

      return fetchData;
    }

    throw new Error('Failed to get location data back from api.');
  }
};
