<template>
  <div>
    <wl-header
      id="app"
      :logo="header.logo"
      :logo-standard-size="logoSize"
      :logo-small="header.logo_small"
      :logo-small-size="logoSmallSize"
      :logo-name="header.name"
      :logo-title="header.logo_title"
      :menu="isMobile ? [] : header.menu.slice(0, menuLinksDesktopCount)"
      :path="path"
      :is-fixed="isFixed"
      :class="{
        'wl-header--mobile-search': mobileSearchActive,
        'wl-header--home': isHome
      }"
    >
      <template #header-bar>
        <ul id="toggle-menu" class="wl-header__toggle-menu">
          <li
            class="wl-header__toggle-menu-item"
            :key="link.label"
            v-for="link in isMobile
              ? header.menu
              : header.menu.slice(menuLinksDesktopCount)"
          >
            <a
              class="wl-header__toggle-menu-link"
              :class="{
                'wl-header__toggle-menu-link--active': path === link.url
              }"
              :href="link.url"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              v-text="link.label"
            />
          </li>
        </ul>
      </template>
    </wl-header>
    <main class="inside-content">
      <nuxt ref="page" :prefill-fields="prefillFields" />
      <tz-footer
        :page="page"
        header-fixed=""
        :footer="footer"
        alpha-base-link="/"
        :display-sections="[
          'alphaSelect',
          'contactInfo',
          'columnSecondary',
          'columnTertiary',
          'columnQuarternary',
          'disclaimer'
        ]"
      >
        <template v-slot:badges>
          <img :src="securityBadges" alt="Trusted site" />
        </template>
      </tz-footer>
    </main>
  </div>
</template>

<script>
// Components
import WlHeader from '@/components/shared/wl-header.vue';
import TzFooter from '@trazi/tz-footer/src/tz-footer.vue';
// Images
import securityBadges from '@/assets/images/logos/security-badges.png';
// Misc
import resultHelper from '@/assets/js/shared/resultHelper';
const mobileMediaQuery = resultHelper.getMobileQuery(992);
const footerCMS = require(`@/assets/cms/components/footer.json`);

let insertionIndex = 1;

for (let i = 1; i < 13; i++) {
  const today = new Date();
  const priorDate = new Date(
    new Date().setDate(today.getDate() - i)
  ).toLocaleDateString(undefined, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  }); // returns MM/DD/YYYY
  const mm = priorDate.split('/')[0];
  const m = priorDate.split('/')[0].replace(/^0/, '');
  const dd = priorDate.split('/')[1];
  const d = priorDate.split('/')[1].replace(/^0/, '');
  const yyy = priorDate.split('/')[2];

  //TODO: update URL with correct link once pages are built
  const dateEntry = {
    label: `${mm}/${dd}`,
    url: '/'
  };

  footerCMS.quarternary_list.splice(insertionIndex, 0, dateEntry);
  insertionIndex++;
}

export default {
  name: 'freepeoplesearch',
  components: {
    WlHeader,
    TzFooter
  },
  data() {
    return {
      securityBadges,
      page: {
        path: this.$route.path,
        name: this.$route.name,
        params: this.$route.params
      },
      prefillFields: {
        firstName: '',
        lastName: ''
      },
      logoSize: { height: '47', width: '276' },
      logoSmallSize: { height: '69', width: '100' },
      menuLinksDesktopCount: 3,
      isMobile: false,
      mobileSearchActive: false,
      path: null,
      isFixed: false
    };
  },
  created() {
    this.path = this.$route.path;
  },
  computed: {
    isHome() {
      return this.$route.path === '/';
    },
    searchOffsetElement() {
      // TODO: Only return until elements are added to page
      return {
        offsetTop: 0,
        offsetHeight: 20
      };

      const bannerSlots = this.$refs.insideContent.querySelector(
        '.tz-banner__slots'
      );

      // If there is a banner search, show and hide header search when scrolled past ex. Home Page
      if (bannerSlots && bannerSlots.offsetHeight > 0) {
        return bannerSlots;
      }

      const bannerDesc = this.$refs.insideContent.querySelector(
        '.tz-banner__desc'
      );

      // If there is a banner with a header, show and hide search when scrolled past ex. Privacy Policy
      if (bannerDesc && bannerDesc.offsetHeight > 0) {
        return bannerDesc;
      }

      // Otherwise default to breadcrumbs ex. City Results Page
      return this.$refs.insideContent.querySelector('.tz-breadcrumbs');
    }
  },
  methods: {
    updatePrefillFields(updatedFields) {
      this.prefillFields = Object.assign({}, this.prefillFields, updatedFields);
    },
    async setLocation() {
      try {
        const location = await this.$location();
        const state = location.state;
        this.updatePrefillFields({ state });
      } catch (error) {
        console.warn(error);
      }
    },
    setIsMobile(e) {
      this.isMobile = e.matches;
    },
    setScrollEvent() {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    },
    handleScroll() {
      this.searchNotVisibleOffset =
        this.searchOffsetElement.offsetTop +
        this.searchOffsetElement.offsetHeight;
      this.isFixed = window.scrollY >= this.searchNotVisibleOffset;
    },
    mobileSearchOpen() {
      this.mobileSearchActive = true;
    },
    mobileSearchClose() {
      this.mobileSearchActive = false;
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    mobileMediaQuery.removeEventListener('change', this.setIsMobile);
  },
  async mounted() {
    //If state is missing use geo location
    if (!this.prefillFields.state) {
      this.setLocation();
    }
    resultHelper.mediaQueryListener(mobileMediaQuery, this.setIsMobile);
    this.isMobile = mobileMediaQuery.matches;

    this.handleScroll();
    this.setScrollEvent();
  },
  props: {
    header: {
      type: Object,
      default: () => require(`@/assets/cms/components/header.json`)
    },
    footer: {
      type: Object,
      default: () => require(`@/assets/cms/components/footer.json`)
    }
  }
};
</script>
