import resultHelper from '@/assets/js/shared/resultHelper';

/**
 * Formats full name from params for new redirect url
 * @param {string} fullName
 * @returns {string} formattedFullName
 */
const formatFullName = function(fullName) {
  let formattedFullName = '';
  for (let i = 0; i < fullName.length; i++) {
    if (i === 0 || fullName[i - 1] === '-' || fullName[i - 1] === '_') {
      formattedFullName += fullName[i].toUpperCase();
      continue;
    }
    formattedFullName += fullName[i];
  }
  return formattedFullName;
};

/**
 * Creates updated seo profile url
 * @param {string} fullPath
 * @returns {string} redirectUrl
 */
const getProfileUrl = function(fullPath) {
  const pathPieces = fullPath.split('/');
  const fullName = formatFullName(pathPieces[3]);
  const stateAbbr = resultHelper.getStateAbbrFromName(pathPieces[4]);
  const city = resultHelper.setTitleCase(pathPieces[5]).replace(' ', '-');
  const id = pathPieces[6];
  return `/name/${fullName}/in-${stateAbbr}/${city}/${id}`;
};

/**
 * Creates url to go to updated seo results pages
 * @param {string} fullPath
 * @returns {string} redirectUrl
 */
const getResultsUrl = function(fullPath) {
  const pathPieces = fullPath.split('/');
  const hasQueries = fullPath.indexOf('?') > -1;
  let fullName;
  let state;
  let city;
  let page;
  if (hasQueries) {
    fullName = pathPieces[3].split('?')[0];
    const queryStr = pathPieces[3].split('?')[1];
    const multipleQueries = pathPieces[3].indexOf('&');
    const pathQueries = multipleQueries ? queryStr.split('&') : queryStr;
    pathQueries.forEach(query => {
      const stateQuery = 'state=';
      const cityQuery = 'city=';
      const pageQuery = 'page=';
      if (query.indexOf(stateQuery) > -1) {
        state = query.replace(stateQuery, '');
      }
      if (query.indexOf(cityQuery) > -1) {
        city = query.replace(cityQuery, '');
      }
      if (query.indexOf(pageQuery) > -1) {
        page = query.replace(pageQuery, '');
      }
    });
  } else {
    fullName = pathPieces[3];
  }

  fullName = formatFullName(fullName);
  const statePiece = state
    ? `/in-${resultHelper.getStateAbbrFromName(state.replace('-', ' '))}`
    : '';
  const cityPiece = city
    ? `/${resultHelper.setTitleCase(city).replace(' ', '-')}`
    : '';
  const pagePiece = page ? `/${page}` : '';

  return `/name/${fullName}${statePiece}${cityPiece}${pagePiece}`;
};

/**
 * Default Redirect Middleware
 * @param route
 * @param redirect
 * @returns {*}
 */
export default async function({ route, redirect }) {
  const isSEOPath = route.fullPath.indexOf('/search/name/') > -1;
  const redirectToProfile = isSEOPath && route.fullPath.split('/').length === 7;
  const redirectToResults = isSEOPath && !redirectToProfile;
  if (!redirectToResults && !redirectToProfile) {
    return;
  }
  let redirectUrl = '';
  if (redirectToProfile) {
    redirectUrl = getProfileUrl(route.fullPath);
  } else if (redirectToResults) {
    redirectUrl = getResultsUrl(route.fullPath);
  }

  return redirect(301, redirectUrl);
}
