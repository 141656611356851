import { render, staticRenderFns } from "./default.vue?vue&type=template&id=3ba343d4&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.10.0_1526cca7f574ee28884ce00d85ec031a/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports