import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from './components/nuxt-error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_toast_26a07210 from 'nuxt_plugin_toast_26a07210' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_workbox_00ccc63d from 'nuxt_plugin_workbox_00ccc63d' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_784e8050 from 'nuxt_plugin_metaplugin_784e8050' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_httpserver_26420d8e from 'nuxt_plugin_httpserver_26420d8e' // Source: ./http.server.js (mode: 'server')
import nuxt_plugin_http_45b33ccf from 'nuxt_plugin_http_45b33ccf' // Source: ./http.js (mode: 'all')
import nuxt_plugin_wog_0d2e763e from 'nuxt_plugin_wog_0d2e763e' // Source: ../plugins/shared/wog (mode: 'server')
import nuxt_plugin_location_4d44ab66 from 'nuxt_plugin_location_4d44ab66' // Source: ../plugins/shared/location (mode: 'client')
import nuxt_plugin_touchevents_8e3f3ad0 from 'nuxt_plugin_touchevents_8e3f3ad0' // Source: ../plugins/shared/touch-events (mode: 'client')
import nuxt_plugin_sentryclient_9a78099e from 'nuxt_plugin_sentryclient_9a78099e' // Source: ../plugins/shared/sentry.client.js (mode: 'client')
import nuxt_plugin_sentryserver_187d6cb9 from 'nuxt_plugin_sentryserver_187d6cb9' // Source: ../plugins/shared/sentry.server.js (mode: 'server')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$freepeoplesearch', {
  get() {
    return this.$root.$options.$freepeoplesearch
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext)

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"hid":"theme-color","name":"theme-color","content":"#3ac8db"},{"hid":"description","name":"description","content":"Enter ANY name to find the person you're looking for! Start your search free and get comprehensive information available including public records, current phone numbers, address history, social media data and more!"},{"hid":"utf-8","charset":"utf-8"},{"hid":"viewport","name":"viewport","content":"width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"},{"hid":"author","name":"author","content":"FreePeopleSearch"},{"hid":"og:title","property":"og:title","content":"FREEPeopleSearch.com | 100% Free Online People Finder & People Lookup"},{"hid":"og:description","property":"og:description","content":"Enter ANY name to find the person you're looking for! Start your search free and get comprehensive information available including public records, current phone numbers, address history, social media data and more!"},{"hid":"og:url","property":"og:url","content":"https:\u002F\u002Ffreepeoplesearch.com\u002F"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:site_name","property":"og:site_name","content":"FreePeopleSearch"}],"link":[{"rel":"icon","href":"\u002Ffavicon.png"},{"rel":"apple-touch-icon","href":"\u002Ftouch.png"}],"style":[],"script":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__freepeoplesearch_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.client && typeof nuxt_plugin_toast_26a07210 === 'function') {
    await nuxt_plugin_toast_26a07210(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_00ccc63d === 'function') {
    await nuxt_plugin_workbox_00ccc63d(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_784e8050 === 'function') {
    await nuxt_plugin_metaplugin_784e8050(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_httpserver_26420d8e === 'function') {
    await nuxt_plugin_httpserver_26420d8e(app.context, inject)
  }

  if (typeof nuxt_plugin_http_45b33ccf === 'function') {
    await nuxt_plugin_http_45b33ccf(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_wog_0d2e763e === 'function') {
    await nuxt_plugin_wog_0d2e763e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_location_4d44ab66 === 'function') {
    await nuxt_plugin_location_4d44ab66(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_touchevents_8e3f3ad0 === 'function') {
    await nuxt_plugin_touchevents_8e3f3ad0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_9a78099e === 'function') {
    await nuxt_plugin_sentryclient_9a78099e(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_187d6cb9 === 'function') {
    await nuxt_plugin_sentryserver_187d6cb9(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, (err) => {
        // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
        if (!err._isRouter) return reject(err)
        if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
