import resultHelper from '@/assets/js/shared/resultHelper';

/**
 * Results Redirect Middleware
 * @param route
 * @param redirect
 * @returns {*}
 */
export default function({ route, redirect }) {
  const { params, fullPath, query } = route;
  const queryParams = Object.keys(query);
  const hasValidState =
    params.stateAbbr && !!resultHelper.getStateFromAbbr(params.stateAbbr);
  let redirectParams = {};
  const hasValidPage = params.page && params.page <= 10;
  const hasValidCity = hasValidState && params.city;
  const hasValidQueryParams = queryParams.length;

  redirectParams.firstName = resultHelper
    .setTitleCase(
      resultHelper.parseParamFirstName(params.firstName.toLowerCase())
    )
    .replace(resultHelper.spaceRegEx, '_')
    .replace(resultHelper.dashRegEx, '');

  redirectParams.lastName = resultHelper
    .setTitleCase(resultHelper.parseParamLastName(params.lastName))
    .replace(resultHelper.spaceRegEx, '_');

  let redirectUrl = `/${redirectParams.firstName}-${redirectParams.lastName}/`;

  if (hasValidState) {
    redirectParams.stateAbbr = params.stateAbbr.toUpperCase();
    redirectUrl = redirectUrl + redirectParams.stateAbbr;
    if (route.name === 'results.state') {
      redirectUrl = redirectUrl + '/';
    }
  }

  if (hasValidCity) {
    redirectParams.city = resultHelper
      .setTitleCase(params.city.replace(resultHelper.underscoreRegEx, ' '))
      .replace(resultHelper.spaceRegEx, '_');
    redirectUrl = redirectUrl + `-${redirectParams.city}/`;
  }

  if (hasValidPage) {
    redirectParams.page = params.page;
    redirectUrl = redirectUrl + redirectParams.page + '/';
  }

  if (hasValidQueryParams) {
    const queryString = queryParams
      .map(key => key + '=' + query[key])
      .join('&');
    redirectUrl = redirectUrl + '?' + queryString;
  }

  if (fullPath !== redirectUrl) {
    return redirect(301, redirectUrl);
  }

  // TODO: Try to get forward slash working on pretty solution for redirect
  // return redirect(301, {
  //   name: route.name,
  //   params: redirectParams,
  //   query: route.query
  // });
}
