import resultHelper from '@/assets/js/shared/resultHelper';

/**
 * Address Page Redirect Middleware
 * @param route
 * @param redirect
 * @returns {*}
 */
export default function({ route, redirect }) {
  const { params, fullPath } = route;
  let redirectParams = {};
  const hasValidPage = params.page && params.page <= 10;

  const redirectStreet = resultHelper
    .setTitleCase(params.street.replace('-', ' '))
    .replace(' ', '-');
  const redirectCity = resultHelper
    .setTitleCase(params.city.replace(resultHelper.underscoreRegEx, ' '))
    .replace(resultHelper.spaceRegEx, '_');
  const redirectState = params.state.toUpperCase();
  let redirectPath = `/address/${redirectStreet}--${redirectCity}-${redirectState}-${params.zipcode}/`;

  if (hasValidPage) {
    redirectParams.page = params.page;
    redirectPath = redirectPath + redirectParams.page + '/';
  }
  if (fullPath !== redirectPath) {
    return redirect(301, redirectPath);
  }
  if (fullPath[fullPath.length - 1] !== '/') {
    return redirect(301, redirectPath + '/');
  }
}
